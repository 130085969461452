import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all_teachers: [],
  teacher_detail: {},
  priority_hours: [],
  selected_date_priority_hours: [],
  student_enroll_course_list_detail: [],
  getAllSchoolExam: '',
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    all_teachers: (state, action) => {
      state.all_teachers = action.payload;
    },
    teacher_detail: (state, action) => {
      state.teacher_detail = action.payload;
    },
    priority_hours: (state, action) => {
      state.priority_hours = action.payload;
    },
    selected_date_priority_hours: (state, action) => {
      state.selected_date_priority_hours = action.payload;
    },
    student_enroll_course_list_detail: (state, action) => {
      state.student_enroll_course_list_detail = action.payload;
    },
    setAllSchoolExam: (state, action) => {
      state.getAllSchoolExam = action.payload;
    },
  },
});

export const {
  setAllSchoolExam,
  all_teachers,
  teacher_detail,
  priority_hours,
  selected_date_priority_hours,
  student_enroll_course_list_detail,
} = commonSlice.actions;

export default commonSlice.reducer;
